.submitButton {
    color: #FFF;
    background-color: #44befb;
    border: 1px solid #44befb;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
    height: auto;
    text-transform: uppercase;
}

.submitButton:hover {
    color: #FFF;
    background-color: #046cc1;
    border: 1px solid #046cc1;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
}

.submitButton:disabled {
    color: #FFF;
    background-color: #aaa;
    border: 1px solid #aaa;
    cursor: no-drop;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
}

.cancelButton {
    color: #888;
    background-color: #FFF ;
    border: 1px solid #888 ;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
    height: auto;
    text-transform: uppercase;
}

.cancelButton:hover {
    color: #555;
    background-color: #FFF;
    border: 1px solid #555;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
}

.cancelButton:disabled {
    color: #aaa;
    background-color: #FFF;
    border: 1px solid #aaa;
    cursor: no-drop;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
}

.successButton {
    color: #FFF;
    background-color: #00cc00;
    border: 1px solid #00cc00;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
    height: auto;
    text-transform: uppercase;
}

.successButton:hover {
    color: #FFF;
    background-color: #009900;
    border: 1px solid #009900;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
}

.successButton:disabled {
    color: #FFF;
    background-color: #aaa;
    border: 1px solid #aaa;
    cursor: no-drop;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 60%);
    margin-bottom: 10px;
    white-space: nowrap;
}