.main_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.top_content_container {
    display: flex;
    flex-direction: row;
    height: auto;
}

.testimonials_container {
    display: flex;
    min-width: 225px;
    width: 225px;
    height: inherit;
}

.carousel_and_cart_history_container {
    display: flex;
    flex-direction: column;
    width: 925px;
}

.carousel_container {
    display: flex;
    width: 100%;
}

.cart_history_container {
    display: flex;
    width: 100%;
    padding: 10px;
}

.bottom_content_container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.guided_search_container {
    display: flex;
    width: 99%;
    justify-content: flex-start;
    padding: 10px 0px 0px 10px;
}

@media (max-width: 1199px) { 
    .carousel_and_cart_history_container {
        display: flex;
        flex-direction: column;
        width: 725px;
    }
}

@media (max-width: 1001px) { 
    .carousel_and_cart_history_container {
        display: flex;
        flex-direction: column;
        width: 725px;
    }

    .top_content_container {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 992px) { 
    .carousel_and_cart_history_container {
        display: flex;
        flex-direction: column;
        width: 500px;
    }
}

@media (max-width: 745px) { 
    .top_content_container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .carousel_and_cart_history_container {
        display: flex;
        flex-direction: column;
        width: inherit;
        order: 1;
    }
    
    .testimonials_container {
        display: flex;
        width: inherit;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .guided_search_container {
        display: flex;
        width: 99%;
        justify-content: flex-start;
        margin: 0px;
    }
}

.section_title_container {
    display: flex;
    justify-content: space-between;
}

.welcome_message_container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.brands_display {
	border: 1px solid #e1e1e1;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.brand_image {
	margin: 1em;
	width: 125px;
	cursor: pointer;
}

.search_tips_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.search_tips_container a:visited {
    color: #046cc1;
}

.title {
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	color: #046cc1;
	margin: 0;
}

.link {
    color: #046cc1;
	font-family: 'Open Sans',Arial,sans-serif;
	font-size: 12px;
}

.link:visited {
    color: #046cc1;
}

.link:hover {
    text-decoration: underline;
}