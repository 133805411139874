.testimonials_container {
	background-color: #151515;
	display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    min-width: 225px;
    scrollbar-width: none;
}

.testimonials_container::-webkit-scrollbar {
    display: none;
}

.testimonials_title {
	width: 100%;
    text-align: center;
    font-weight: 600;
    padding-top: 10px;
	margin: 0px;
    color: #FFF;
    height: 40px;
}

.testimonials_box {
	width: 100%;
	background-color: #151515;
	display: flex;
    flex-direction: column;
	justify-content: space-around;
}

.testimonial {
	padding: 15px;
}

@media (max-width: 745px) { 
    .testimonials_container {
        background-color: #151515;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 5px;
        height: auto;
    }

    .testimonials_box {
        width: 100%;
        background-color: #151515;
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
        flex-direction: row;
        border-radius: 5px;
    }

    .testimonial {
        padding: 15px;
        width: 33%;
    }

    
    @media (max-width: 600px) {
        .testimonials_box {
            width: 100%;
            background-color: #151515;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            flex-direction: column;
        }

        .testimonial {
            width: 100%;
            padding: 15px;
        }
    }
}


.testimonial_text {
    color: #898989;
    font-style: italic;
}

.expand_button {
    color: #B3B3B3;
    cursor: pointer;
    font-size: 12px;
}

.link {
    color: #898989;
    font-size: 14px;
}

.expand_button:hover {
    color: #44befb;
}