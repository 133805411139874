.cart_history {
	border: 1px solid #e1e1e1;
    padding: 10px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
	position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 297px;
    width: 100%;
}

.cart_history_no_products {
    border: 1px solid #e1e1e1;
    padding: 10px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
	position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 345px) {
    .cart_history {
        border: 1px solid #e1e1e1;
        padding: 10px 0px 10px 0px;
        margin: 0px 0px 10px 0px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: auto;
        width: 100%;
    }
    
    .products_container {
        display: flex;
        justify-content: space-between;
        flex-grow: 4;
        order: 3;
    }

    .arrow_container_left {
        align-self: center;
        width: 50%;
        flex-grow: 1;
        text-align: left;
        order: 1;
    }

    .arrow_container_right {
        align-self: center;
        width: 50%;
        flex-grow: 1;
        text-align: right;
        order: 2;
    }
}

.title_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
	text-align: left;
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	color: #046cc1;
	margin: 0;
}

.cart_link {
	color: #046cc1;
	font-family: 'Open Sans',Arial,sans-serif;
	font-size: 12px;
}

.cart_link:hover {
    text-decoration: underline;
}

.cart_link:visited {
    color: #046cc1;
}

.products_container {
    display: flex;
    justify-content: space-between;
    flex-grow: 4;
}

.arrow_container_left {
    align-self: center;
    flex-grow: 1;
    text-align: left;
}

.arrow_container_right {
    align-self: center;
    flex-grow: 1;
    text-align: right;
}