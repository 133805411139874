.container {
    background-color: #E9EAEC;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 4px solid #44a8e3;
}

.titleContainer {
    display: flex;
    padding-right: 20px;
}

.iconAndHeaderContainer {
    display: flex;
    flex-wrap: nowrap;
}

.header {
    margin: 0px;
    width: 175px;
}

.searchContainer {
    display: flex;
    flex-direction: column;
}

.searchInstructions {
    width: 100%;
    height: 39px;
    margin: 0px;
    padding: 0px 0px 20px 0px;
    text-align: left;
}

.inputGroup {
    display: flex;
    width: inherit;
    justify-content: flex-start;
}

.inputContainer {
    display: flex;
    width: 170px;
    padding-top: 5px;
}

.input {
    margin: 0px;
    width: 150px;
}

.buttonContainer {
    display: flex;
}

@media (max-width: 1000px) {
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
    
    .titleContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-bottom: 10px;
    }
}

@media (max-width: 992px) {
    .inputGroup {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .buttonContainer {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        width: 100%;
    }
}

@media (max-width: 721px) {
    .inputContainer {
        display: flex;
        width: 170px;
        padding-bottom: 2px;
    }
}